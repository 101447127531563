import { usePrismicDocumentsByType } from "@prismicio/react";
import { ThreeDots } from "react-loader-spinner";

const KeyStackHolders = () => {
    const [stackholders] = usePrismicDocumentsByType("homepage_stackholder", {
        orderings: {
            field: "first_publication_date",
            direction: "desc",
        },
    });

    return (
        <section className='project-area pt-100 pb-70'>
            <div className='container'>
                <div className='section-title'>
                    <h2 data-aos='fade-up' data-aos-duration='1000'>
                        Key Stakeholders
                    </h2>
                </div>

                <div className='row justify-content-center align-items-center'>
                    {stackholders?.results?.map((stackholder, id) => (
                        <StackHolder
                            key={stackholder.id}
                            image={stackholder?.data?.stackholder_logo.url}
                            // worldbank={true}
                            title={stackholder?.data?.stackholder_name}
                            alt={stackholder?.data?.stackholder_name}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

const StackHolder = ({ image, title, worldbank, alt }) => {
    return (
        <div className='col-lg-2 col-md-6 mb-4'>
            <div className='single-project- text-center' data-aos='zoom-in-up'>
                <img
                    width={worldbank ? 144.49 : 96}
                    height={worldbank ? 96 : 64}
                    src={image}
                    alt={alt || "Image"}
                    className='mb-4'
                    style={{ objectFit: "contain" }}
                />

                <p
                    className=''
                    style={{
                        fontSize: "16px",
                        fontWeight: 600,
                    }}
                >
                    {title}
                </p>
            </div>
        </div>
    );
};

export default KeyStackHolders;
