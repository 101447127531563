import moment from "moment";
import React from "react";
import { NavLink } from "react-router-dom";
import { usePrismicDocumentsByType } from "@prismicio/react";
import { ThreeDots } from "react-loader-spinner";

const LatestNews = () => {
    const [news, { state }] = usePrismicDocumentsByType("news", {
        fetchLinks: ["category.title"],
        graphQuery: `{
            news{
                title
                feature_image
                short_description
                category
            }
        }`,
        orderings: [
            {
                field: "first_publication_date",
                direction: "desc",
            },
        ],
        pageSize: 3,
    });

    return (
        <section className='container mt-5'>
            <div className='section-title'>
                <h2 data-aos='fade-up'>Latest News</h2>
            </div>

            <div
                className='row align-items-stretch justify-content-start '
                style={{ padding: ".2rem" }}
            >
                {state === "loading" ? (
                    <div
                        style={{
                            width: "100%",
                            height: 300,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ThreeDots
                            visible={true}
                            height='80'
                            width='80'
                            color='#4fa94d'
                            radius='9'
                            ariaLabel='three-dots-loading'
                            wrapperStyle={{}}
                            wrapperClass=''
                        />
                    </div>
                ) : state === "failed" ? (
                    <div
                        style={{
                            width: "100%",
                            height: 300,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <p>
                            An error occurred while attempting to fetch the
                            latest news.
                        </p>
                    </div>
                ) : (
                    news?.results?.map((news) => (
                        <EachRelease
                            key={news?.uid}
                            category={
                                news?.data?.category?.data?.title[0]?.text
                            }
                            title={news?.data?.title[0]?.text}
                            img={news?.data?.feature_image?.url}
                            created_at={news?.first_publication_date}
                            alt={news?.data?.image?.alt}
                            description={news?.data?.description}
                            categoryLink={`${"/news-and-events/press-release/"}${
                                news?.data?.category?.uid
                            }`}
                            link={`/news-and-events/press-release/${news?.data?.category?.uid}/${news?.uid}`}
                        />
                    ))
                )}
            </div>
        </section>
    );
};

const EachRelease = ({
    link,
    title,
    category,
    created_at,
    img,
    alt,
    short_description,
    categoryLink,
}) => {
    return (
        <div
            className='col-lg-4 col-md-6'
            data-aos-delay='50'
            data-aos='fade-up'
            data-aos-duration='1000'
        >
            <div className='single-blog-box'>
                <NavLink
                    style={{
                        display: "block",
                        height: "350px",
                        position: "relative",
                    }}
                    to={link}
                >
                    <img
                        src={img}
                        alt={alt || "image"}
                        style={{
                            objectFit: "cover",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                        }}
                    />
                </NavLink>

                <div className='blog-content'>
                    <ul>
                        <li>
                            <NavLink to={categoryLink}>{category}</NavLink>
                        </li>
                        <li>
                            <i className='ri-calendar-line'></i>
                            {moment(created_at).format("MMMM DD, YYYY")}
                        </li>
                    </ul>
                    <h3>
                        <NavLink to={link}>{title}</NavLink>
                    </h3>
                    <p>{short_description}</p>
                    <NavLink to={link} className='read-more'>
                        Read more
                        <i className='ri-arrow-right-s-line'></i>
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default LatestNews;
