const institutionAgreement = [
    {
        section_name: "Federal Level",
        fields: [
            {
                img: "/assets/figma/coat_of_arm.png",
                img_title: "Federal Ministry of Education",
                name: "Federal Ministry of Education (FMOE)",
                description:
                    "The Federal Ministry of Education is the ultimate sectoral authority for the project, responsible for oversight in the achievement of the project DLIs; strengthening the education planning and resource allocation in three states and sharing the experience in education planning sector wide. It also ensures monitoring and reporting on TESS implementation progress and results toward achieving system transformation.",
            },
            {
                img: "/assets/figma/ube.png",
                img_title: "",
                name: "Universal Basic Education Commission (UBEC)",
                description:
                    "The Universal Basic Education Commission Is responsible for facilitating the implementation of UBE programs, supported by SUBEB at the state level in collaboration with the Federal Ministry of Education and Development Partners (DPs) to ensure conduct of annual joint sector reviews in OAK states to monitor the implementation of the ESPs and the BESDA AF-TESS.",
            },
            {
                img: "/assets/figma/ministry_of_finance.png",
                img_title: "",
                name: "Federal Ministry of Finance, Budget, and National Planning (FMFBNP)",
                description:
                    "Federal Ministry of Finance Budget and National Planning provides policy guidance on fiscal matters.",
            },
        ],
    },
    {
        section_name: "State Level",
        fields: [
            {
                img: "/assets/figma/coat_of_arm.png",
                img_title: "State Ministry of Education",
                name: "State Ministry of Education (SMOE)",
                description:
                    "Responsible for the development of the school network to identify the need for additional schools and priorities for infrastructure improvement in existing schools; develop and implement a need-based teacher deployment policy; conduct learning assessments in core subjects and publish education budget spending in collaboration with the State General Accountant’s office.",
            },
            {
                img: "/assets/figma/ube.png",
                img_title: "",
                name: "State Universal Basic Education Board (SUBEB)",
                description:
                    "Responsible for allocating school infrastructure budget for underserved communities and supporting SBMCs that receive the funding to implement school construction and infrastructure improvement projects; development of pedagogical support materials and monitoring of Teacher Professional Development; and reporting on State UBE program expenditures reported by activity and outputs at student, teacher and school levels.",
            },
            {
                img: "/assets/figma/coat_of_arm.png",
                img_title: "Local Government Education Authority",
                name: "Local Government Education Authority (LGEA)",
                description:
                    "Responsible for teacher deployment/redeployment within its boundary with support from SMOE and SUBEB.",
            },
            {
                img: "/assets/figma/coat_of_arm.png",
                img_title: "School Based Management Committe",
                name: "School Based Management Committee (SBMC)",
                description:
                    "Responsible for managing and overseeing infrastructure upgrading and construction, the grants, and teacher professional coaching support.",
            },
        ],
    },
];
export default institutionAgreement;
