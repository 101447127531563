import { useEffect, useState } from "react";
// import styles from "./HomeHero.module.css";

const slides = [
    {
        heading:
            "Welcome to Transforming Education Systems at States Level (TESS)",
        subheading: "Revolutionizing systems in the education sector",
        image: "/assets/images/student_1.jpg",
    },
    {
        heading:
            "Increase equitable access for out-of-school children focused on equity",
        subheading: "Join us in making education accessible to all.",
        image: "/assets/images/student_2.jpg",
    },
    {
        heading:
            "Improve literacy rate and learning assessment focused on learning outcomes.",
        subheading: "Empowering students with better learning tools.",
        image: "/assets/images/student_3.jpg",
    },
    {
        heading:
            "Strengthen accountability for results focused on system strengthening",
        subheading: "Building a robust educational framework.",
        image: "/assets/images/student_4.jpg",
    },
];

export default function HomeHero() {
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentSlide((prev) =>
                prev === slides.length - 1 ? 0 : prev + 1
            );
        }, 5000);
        return () => clearInterval(timer);
    }, []);

    return (
        <section className={"heroSection"}>
            <div className={"slidesContainer"}>
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        className={`${"slide"} ${
                            currentSlide === index ? "active" : ""
                        }`}
                        style={{
                            backgroundImage: `url(${slide.image})`,
                            // backgroundRepeat: "no-repeat",
                            // backgroundSize: "cover",
                        }}
                    >
                        <div className={"textContainer"}>
                            <h1>{slide.heading}</h1>
                            <h3>{slide.subheading}</h3>
                        </div>
                    </div>
                ))}
            </div>
            <div className={"pagination"}>
                {slides.map((_, index) => (
                    <span
                        key={index}
                        className={`${"dot"} ${
                            currentSlide === index ? "activeDot" : ""
                        }`}
                        onClick={() => setCurrentSlide(index)}
                    />
                ))}
            </div>
        </section>
    );
}
