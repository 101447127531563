import {
    usePrismicDocumentByUID,
    usePrismicDocumentsByType,
} from "@prismicio/react";
import React from "react";
// import BlogDetails from "../../../../../components/BlogDetails";
import { useParams } from "react-router-dom";
import BlogDetails from "../../../../../components/BlogDetails";
import * as prismic from "@prismicio/client";
import { ThreeDots } from "react-loader-spinner";

const EventDetailsPage = () => {
    const { slug } = useParams();

    const [event, { state }] = usePrismicDocumentByUID("event", slug, {
        // fetchLinks: ["category.title"],
    });

    const [categories] = usePrismicDocumentsByType("category");
    const [relatedEvents] = usePrismicDocumentsByType("event", {
        filters: [prismic.filter.not("my.event.uid", slug)],
        fetchLinks: ["category.title"],
        graphQuery: `{
            event{
                title
                feature_image
                short_description
                location
            }
        }`,
        pageSize: 4,
        orderings: [
            {
                field: "first_publication_date",
                direction: "desc",
            },
        ],
    });

    return (
        <>
            <BlogDetails
                post={event}
                categories={categories?.results}
                link={"/news-and-events/events/"}
                checkEvent={true}
                heading={"Events"}
                publicationType={"event"}
                relatedPosts={relatedEvents?.results}
                state={state}
            />
        </>
    );
};

export default EventDetailsPage;
