import {
    usePrismicDocumentByUID,
    usePrismicDocumentsByType,
} from "@prismicio/react";
import React from "react";
import { useParams } from "react-router-dom";
import * as prismic from "@prismicio/client";
import BlogDetails from "../../../../../components/BlogDetails";

const ReportsDetailsPage = () => {
    const { slug } = useParams();

    const [report, { state }] = usePrismicDocumentByUID("report", slug, {
        fetchLinks: ["category.title"],
    });
    const [relatedReport] = usePrismicDocumentsByType("report", {
        filters: [prismic.filter.not("my.report.uid", slug)],
        fetchLinks: ["category.title"],
        graphQuery: `{
            report{
                title
                feature_image
                short_description
                category
            }
        }`,
        pageSize: 4,
        orderings: [
            {
                field: "first_publication_date",
                direction: "desc",
            },
        ],
    });

    const [categories] = usePrismicDocumentsByType("category");

    return (
        <BlogDetails
            post={report}
            categories={categories?.results}
            link={"/publications/news-letters/"}
            heading={"Report"}
            relatedPosts={relatedReport?.results}
            publicationType={"report"}
            state={state}
        />
    );
};

export default ReportsDetailsPage;
