import {
    usePrismicDocumentByUID,
    usePrismicDocumentsByType,
} from "@prismicio/react";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import * as prismic from "@prismicio/client";
import Blog from "../../../../components/Blog";

const NewsletterCategoryPage = () => {
    const [searchParams] = useSearchParams();

    const { category } = useParams();
    const [currentCategory] = usePrismicDocumentByUID("category", category);

    const [newsletters, { state }] = usePrismicDocumentsByType("newsletter", {
        filters: [
            prismic.filter.at("my.newsletter.category", currentCategory?.id),
        ],
        fetchLinks: ["category.title"],
        graphQuery: `{
            newsletter{
                title
                feature_image
                short_description
                category
            }
        }`,
        orderings: [
            {
                field: "first_publication_date",
                direction: "desc",
            },
        ],
        pageSize: 10,
        page: searchParams.get("page") || 1,
    });
    const [categories] = usePrismicDocumentsByType("category");
    return (
        <Blog
            heading={"Newsletters"}
            posts={newsletters?.results}
            totalPages={newsletters?.total_pages}
            categories={categories?.results}
            link={"/publications/news-letters/"}
            state={state}
        />
    );
};

export default NewsletterCategoryPage;
