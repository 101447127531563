/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import moment from "moment";
import { NavLink } from "react-router-dom";

const EventCard = ({ category, date, location, image, title, link, alt }) => {
    return (
        <div className='col-lg-6 col-md-6'>
            <div className='single-event-box '>
                <NavLink
                    style={{
                        height: "400px",
                        position: "relative",
                        display: "block",
                    }}
                    to={link}
                >
                    <img
                        src={image}
                        alt={alt || "single event"}
                        style={{
                            objectFit: "cover",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                        }}
                    />
                </NavLink>

                <div className='event-content'>
                    <ul>
                        <li>
                            <a
                                style={{
                                    background: "#00aa55",
                                    color: "white",
                                    padding: "2px 6px",
                                    borderRadius: "8px",
                                }}
                            >
                                {category.slice(0, 1).toUpperCase() +
                                    category.slice(1)}{" "}
                            </a>
                            <span>{moment(date).format("MMMM DD, YYYY")} </span>
                        </li>
                    </ul>
                    <h3>
                        <NavLink to={link}>{title}</NavLink>
                    </h3>
                    <p>
                        <i className='ri-map-pin-2-line'></i> {location}
                    </p>
                    <NavLink to={link} className='read-more'>
                        Read more
                        <i className='ri-arrow-right-s-line'></i>
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default EventCard;
