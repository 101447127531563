import { usePrismicDocumentsByType } from "@prismicio/react";
import React from "react";
import PhotoGallery from "../../../components/PhotoGallery";
import { useSearchParams } from "react-router-dom";

const PhotoGalleryPage = () => {
    const [searchParams] = useSearchParams();

    const [photos, { state }] = usePrismicDocumentsByType("photo_gallery", {
        fetchLinks: ["category.title"],

        orderings: [
            {
                field: "first_publication_date",
                direction: "desc",
            },
        ],
        pageSize: 9,
        page: searchParams.get("page") || 1,
    });
    const [categories] = usePrismicDocumentsByType("media_room_category");

    return (
        // <>Page</>
        <PhotoGallery
            tabs_category={categories?.results}
            photos={photos?.results}
            totalPages={photos?.total_pages}
            state={state}
        />
    );
};

export default PhotoGalleryPage;
