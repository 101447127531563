export const navLinks = [
    {
        name: "Home",
        url: "/",
    },
    {
        name: "Who We Are",
        url: "/who-we-are",
    },
    {
        name: "What We Do",
        url: "/what-we-do",
    },
    {
        name: "Media Room",
        children: [
            {
                name: "Video Gallery",
                url: "/media-room/video-gallery",
            },
            {
                name: "Photo Gallery",
                url: "/media-room/photo-gallery",
            },
        ],
    },
    {
        name: "News & Events",
        children: [
            {
                name: "New/Press Releases",
                url: "/news-and-events/press-release",
            },
            {
                name: "Events",
                url: "/news-and-events/events",
            },
        ],
    },
    {
        name: "Publications",
        children: [
            {
                name: "News Letters",
                url: "/publications/news-letters",
            },
            {
                name: "Reports",
                url: "/publications/reports",
            },
            {
                name: "Blog",
                url: "/publications/blog",
            },
            {
                name: "Resources",
                url: "/publications/resources",
            },
        ],
    },
];
