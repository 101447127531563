import {
    usePrismicDocumentByUID,
    usePrismicDocumentsByType,
} from "@prismicio/react";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import * as prismic from "@prismicio/client";
import Blog from "../../../../components/Blog";

const ResourceCategoryPage = () => {
    const [searchParams] = useSearchParams();

    const { category } = useParams();
    const [currentCategory] = usePrismicDocumentByUID("category", category);

    const [resources, { state }] = usePrismicDocumentsByType("resource", {
        filters: [
            prismic.filter.at("my.resource.category", currentCategory?.id),
        ],
        fetchLinks: ["category.title"],
        orderings: [
            {
                field: "first_publication_date",
                direction: "desc",
            },
        ],
        pageSize: 10,
        page: searchParams.get("page") || 1,
    });
    const [categories] = usePrismicDocumentsByType("category");

    return (
        <Blog
            heading={"Resources"}
            isResource={true}
            posts={resources?.results}
            link={"/publications/resources/"}
            totalPages={resources?.total_pages}
            categories={categories?.results}
            state={state}
        />
    );
};

export default ResourceCategoryPage;
