import React from "react";

const whatwedoData = [
    {
        image: "https://images.prismic.io/tess-website/4d0b495e-5973-4313-b206-f8f4b9403868_reduction_in_out_of_school_children.jpg?auto=compress,format",
        title: "Reduction in the number of out-of-school children, focused on equity",
        paragraph:
            "Our focus on equity drives this results area, aiming to ensure more equitable allocation of state education resources. Specifically, we work towards improving learning conditions in the most disadvantaged schools and providing opportunities for students.",
    },
    {
        image: "https://images.prismic.io/tess-website/e4553ac7-f980-447a-9e5a-77f3a1fed40f_teaching_practice.jpg?auto=compress,format",
        title: "Enhancing teaching practices and measuring learning outcomes, focused on learning outcomes.",
        paragraph:
            "At TESS, we are committed to improving the quality of education by enhancing teaching practices and measuring learning outcomes. Our focus is to strengthen teachers' capacity in OAK states through a structured pedagogy program based on digital technology.",
    },
    {
        image: "https://images.prismic.io/tess-website/dee99137-4f87-496d-ae4f-0fa1c030e0e4_teacher_develop.jpg?auto=compress,format",
        title: "Improving teacher deployment and making education expenditures Transparent and accessible For Policy Formulation, Focused on efficiency and system management.",
        paragraph:
            "The importance of proper infrastructure in facilitating effective education delivery cannot be over-emphasized. The program aims to improve school facilities, including classrooms, libraries, laboratories, and technology resources, creating an environment conducive to teaching and learning",
    },
];

const WhatWeDo = () => {
    return (
        <section
            className='events-area pt-100 pb-70'
            style={{ backgroundColor: "#F9FAFB" }}
        >
            <div className='container'>
                <div className='pb-5 text-start'>
                    <h2
                        style={{
                            fontFamily: "Syne !important",

                            color: "#F6FEF9",
                            WebkitTextStroke: "1.5px #12B76A",
                            WebkitTextFillColor: "transparent",
                        }}
                        className=' font-60 fw-700 section-title'
                        data-aos='fade-up'
                    >
                        What we do
                    </h2>
                </div>
            </div>

            <div className='container'>
                <div
                    className='row align-items-center'
                    style={{ marginBottom: "12px", gap: "3rem" }}
                >
                    {whatwedoData.map((pillar, index) => (
                        <WhatWeDoContent
                            key={`pillar_${index}`}
                            image={pillar.image}
                            number={index + 1}
                            title={pillar.title}
                            paragraph={pillar.paragraph}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default WhatWeDo;

const WhatWeDoContent = ({ number, title, paragraph, image, reverse }) => {
    return (
        <div
            className={`d-flex align-items-center justify-content-center `}
            style={{
                gap: "2rem",
                flexWrap: "wrap",
                flexDirection: reverse && "row-reverse",
            }}
        >
            <div
                className='col-lg-5 '
                style={{ borderRadius: "10px", overflow: "hidden" }}
            >
                <img
                    src={image}
                    alt='Image'
                    data-aos='fade-up-right'
                    style={{ margin: "auto", objectFit: "scale-down" }}
                />
            </div>

            <div className='col-lg-6'>
                <div className='counselor-content pl-15'>
                    <div className='single-counselor'>
                        <div className='flex ' style={{ marginBottom: "20px" }}>
                            <div
                                className='rounded-full d-flex justify-content-center align-items-center text-theme fw-500'
                                style={{
                                    backgroundColor: "#EAECF0",
                                    width: "72px",
                                    height: "72px",
                                    fontSize: "32px",
                                    boxShadow: "0px 0px 0px 24px #F1F4F8",
                                    marginBottom: "32px",
                                }}
                                data-aos='fade-up'
                            >
                                <span>{number}</span>
                            </div>
                        </div>
                        <h4
                            className='font-30 text-theme fw-500 text-justify'
                            style={{
                                marginBottom: "20px",
                                textTransform: "initial",
                            }}
                            data-aos-delay='50'
                            data-aos='fade-up'
                        >
                            {title}
                        </h4>
                        <p
                            className='font-20 text-gray fw-300 text-justify'
                            data-aos-delay='150'
                            data-aos='fade-up'
                        >
                            {paragraph}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
