import React from "react";
import HeroSection from "./HeroSection";
import Partners from "./Partners";
import Quote from "./Quote";
import FactsAndFigures from "./FactsAndFigure";
import WhoWeAre from "./WhoWeAre";
import WhatWeDo from "./WhatWeDo";
import LatestNews from "./LatestNews";
import ResultArea from "./ResultArea";
import KeyStackHolders from "./KeystakeHolders";

const Homepage = () => {
    return (
        <>
            <HeroSection />
            <Partners />
            <Quote />
            <FactsAndFigures />
            <WhoWeAre />
            <WhatWeDo />
            <LatestNews />
            <ResultArea />
            <KeyStackHolders />
        </>
    );
};

export default Homepage;
