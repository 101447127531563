import React from "react";
import { Route, Routes as Switch } from "react-router";
import VideoGallery from "./video-gallery";
import VideoGalleryCategory from "./video-gallery/category";
import VideoGalleryDetails from "./video-gallery/category/detail";
import PhotoGallery from "./photo-gallery";
import PhotoGalleryCategory from "./photo-gallery/category";
import PageNotFound from "../404";
// import PhotoGalleryDetails from "./photo-gallery/category/detail";

const MediaRoomRoutes = () => {
    return (
        <Switch>
            <Route path='/video-gallery'>
                <Route index element={<VideoGallery />} />
                <Route path=':category/*'>
                    <Route index element={<VideoGalleryCategory />} />
                    <Route path=':slug' element={<VideoGalleryDetails />} />
                </Route>
            </Route>
            <Route path='/photo-gallery'>
                <Route index element={<PhotoGallery />} />
                <Route path=':category'>
                    <Route index element={<PhotoGalleryCategory />} />
                    {/* <Route
                        path=':category/:id'
                        element={<PhotoGalleryDetails />}
                    /> */}
                </Route>
            </Route>
            <Route path='*' element={<PageNotFound />} />
        </Switch>
    );
};

export default MediaRoomRoutes;
