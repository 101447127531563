import React from "react";
import { NavLink } from "react-router-dom";

const PageNotFound = () => {
    return (
        <div className='error-area ptb-100'>
            <div className='d-table'>
                <div className='d-table-cell'>
                    <div className='error-content'>
                        <img src='/assets/images/404.jpg' alt='Image' />
                        <h3>Oops! Page Not Found</h3>
                        <p>The page you were looking for could not be found.</p>

                        <NavLink to='/' className='default-btn two'>
                            Return To Home Page
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;
