import {
    usePrismicDocumentByUID,
    usePrismicDocumentsByType,
} from "@prismicio/react";
import React from "react";
import { useParams } from "react-router-dom";
import * as prismic from "@prismicio/client";
import BlogDetails from "../../../../../components/BlogDetails";

const BlogDetailsPage = () => {
    const { slug } = useParams();

    const [blog, { state }] = usePrismicDocumentByUID("blog", slug, {
        fetchLinks: ["category.title"],
    });
    const [relatedblog] = usePrismicDocumentsByType("blog", {
        filters: [prismic.filter.not("my.blog.uid", slug)],
        fetchLinks: ["category.title"],
        graphQuery: `{
            blog{
                title
                feature_image
                short_description
                category
            }
        }`,
        pageSize: 4,
        orderings: [
            {
                field: "first_publication_date",
                direction: "desc",
            },
        ],
    });

    const [categories] = usePrismicDocumentsByType("category");

    return (
        <BlogDetails
            post={blog}
            categories={categories?.results}
            link={"/publications/blog/"}
            heading={"Blog"}
            relatedPosts={relatedblog?.results}
            publicationType={"blog"}
            state={state}
        />
    );
};

export default BlogDetailsPage;
