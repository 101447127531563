import {
    usePrismicDocumentByUID,
    usePrismicDocumentsByType,
} from "@prismicio/react";
import React from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import * as prismic from "@prismicio/client";
import Blog from "../../../../components/Blog";

const PressReleaseCategoryPage = () => {
    const { category } = useParams();
    const [searchParams] = useSearchParams();

    const [currentCategory] = usePrismicDocumentByUID("category", category);

    const [categories] = usePrismicDocumentsByType("category");

    const [news] = usePrismicDocumentsByType("news", {
        filters: [prismic.filter.at("my.news.category", currentCategory?.id)],
        pageSize: 10,
        page: searchParams.get("page") || 1,
        graphQuery: `{
            news{
                title
                feature_image
                short_description
                category
            }
        }`,
        orderings: [
            {
                field: "first_publication_date",
                direction: "desc",
            },
        ],
    });
    return (
        <Blog
            heading={"News and Press Release"}
            posts={news?.results}
            totalPages={news?.total_pages}
            categories={categories?.results}
            link={"/news-and-events/press-release/"}
        />
    );
};

export default PressReleaseCategoryPage;
