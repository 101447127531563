import React from "react";
import { NavLink } from "react-router-dom";

export default function EachVideo({ title, thumbnail, link, alt }) {
    return (
        <div
            className='col-lg-4 col-md-6'
            style={{ overflow: "hidden" }}
            bis_skin_checked='1'
        >
            <div className='single-project' bis_skin_checked='1'>
                <NavLink
                    to={link}
                    className='ri-play-fill text-theme shadow'
                    style={{
                        position: "absolute",
                        bottom: "40%",
                        right: "45%",
                        fontSize: "90px",
                        zIndex: 10,
                        background: "rga(0,0,0,0.5)",
                        borderRadius: "20px",
                    }}
                ></NavLink>
                <div style={{ position: "relative", height: "400px" }}>
                    <img
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}
                        src={thumbnail}
                        alt={alt || "thumbnail image"}
                    />
                </div>

                <div className='project-content' bis_skin_checked='1'>
                    <NavLink to={link}>{title}</NavLink>
                </div>
            </div>
        </div>
    );
}
