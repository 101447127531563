import { PrismicRichText, usePrismicDocumentByUID } from "@prismicio/react";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";

const VideoGalleryDetails = () => {
    const { slug } = useParams();

    const [videoData, { state }] = usePrismicDocumentByUID(
        "video_galle",
        slug,
        {
            fetchLinks: ["category.title"],
        }
    );

    return (
        <>
            <div>
                <div
                    className='bg-1 bg-theme-'
                    style={{ height: "112px", backgroundColor: "#12B76A" }}
                >
                    <div className='container d-flex flex-column justify-content-center h-100'>
                        <h1 className='fw-400 text-white'>Video Gallery</h1>
                    </div>
                </div>
                <section className='blog-details-area ptb-100'>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-6'>
                                <div className='blog-details-content'>
                                    <div
                                        className='blog-details-img'
                                        style={{ height: "450px" }}
                                        bis_skin_checked='1'
                                    >
                                        <video
                                            preload='none'
                                            className='w-100 h-100 video_poster'
                                            controls
                                            src={videoData?.data?.video?.url}
                                            poster={
                                                videoData?.data?.thumbnail_image
                                                    ?.url
                                            }
                                        />
                                    </div>
                                    <div className='blog-top-content'>
                                        <div className='news-content'>
                                            <ul className='admin d-flex'>
                                                <li>
                                                    <i className='ri-calendar-line'></i>
                                                    {moment(
                                                        videoData?.first_publication_date
                                                    ).format("DD MMMM")}
                                                </li>
                                            </ul>

                                            <h2>
                                                {videoData?.data?.title &&
                                                    videoData?.data?.title[0]
                                                        ?.text}
                                            </h2>

                                            {
                                                <PrismicRichText
                                                    field={
                                                        videoData?.data?.content
                                                    }
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default VideoGalleryDetails;
