import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PrismicProvider } from "@prismicio/react";
import { client } from "./prismic";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// import "./assets/css/bootstrap.min.css";
// import "./assets/css/owl.theme.default.min.css";
// import "./assets/css/remixicon.css";
// import "./assets/css/flaticon.css";
// import "./assets/css/animate.min.css";
// import "./assets/css/odometer.min.css";
// import "./assets/css/date-picker.min.css";
// import "./assets/css/responsive.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <PrismicProvider client={client}>
                    <App />
                </PrismicProvider>
            </QueryClientProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
