import {
    usePrismicDocumentByUID,
    usePrismicDocumentsByType,
} from "@prismicio/react";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import * as prismic from "@prismicio/client";
import VideoGallery from "../../../../components/VideoGallery";

const VideoGalleryCategory = () => {
    const { category } = useParams();
    const [searchParams] = useSearchParams();

    const [currentCategory] = usePrismicDocumentByUID(
        "media_room_category",
        category
    );

    const [videos, { state }] = usePrismicDocumentsByType("video_galle", {
        fetchLinks: ["category.title"],
        filters: [
            prismic.filter.at("my.video_galle.category", currentCategory?.id),
        ],
        graphQuery: `{
            video_galle{
                thumbnail
                category
            }
        }

        `,
        orderings: [
            {
                field: "first_publication_date",
                direction: "desc",
            },
        ],
        pageSize: 9,
        page: searchParams.get("page") || 1,
    });
    const [categories] = usePrismicDocumentsByType("media_room_category");

    return (
        <VideoGallery
            tabs_category={categories?.results}
            videos={videos?.results}
            totalPages={videos?.total_pages}
            state={state}
        />
    );
};

export default VideoGalleryCategory;
