import React, { useEffect, useState } from "react";
import BlogSearch from "./BlogSearch";
import { ThreeDots } from "react-loader-spinner";
// import { useRouter } from "next/router";

const SearchResults = ({
    query,
    totalPages,
    posts,
    isLoading,
    total_result,
}) => {
    const [data, setData] = useState({
        blogPosts: [],
        press_release: [],
        video_gallery: [],
        image_gallery: [],
        events: [],
        reports: [],
    });

    useEffect(() => {
        if (posts) {
            setData({
                blogPosts: posts.filter((post) => post.type === "blog"),
                press_release: posts.filter((post) => post.type === "news"),
                video_gallery: posts.filter(
                    (post) => post.type === "video_galle"
                ),
                image_gallery: posts.filter(
                    (post) => post.type === "photo_gallery"
                ),
                events: posts.filter((post) => post.type === "event"),
                reports: posts.filter((post) => post.type === "report"),
            });
        }
    }, [posts]);

    return (
        <main className='container pb-100'>
            <h3 style={{ fontSize: "24px" }}>Search results</h3>
            {isLoading ? (
                <div
                    style={{
                        minHeight: "100vh",
                        display: "grid",
                        placeItems: "center",
                    }}
                >
                    <ThreeDots
                        visible={true}
                        height='80'
                        width='80'
                        color='#4fa94d'
                        radius='9'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{}}
                        wrapperClass=''
                    />
                </div>
            ) : (
                <>
                    {" "}
                    {total_result > 0 ? (
                        <>
                            {data?.blogPosts.length > 0 && (
                                <BlogSearch
                                    posts={data?.blogPosts}
                                    title={"Blog"}
                                    queryWord={query}
                                    link={"/publications/blog"}
                                />
                            )}
                            {data?.press_release.length > 0 && (
                                <BlogSearch
                                    posts={data?.press_release}
                                    title={"Press Release"}
                                    queryWord={query}
                                    link={"/news-and-events/press-release"}
                                />
                            )}
                            {data?.events.length > 0 && (
                                <BlogSearch
                                    posts={data?.events}
                                    title={"Events"}
                                    queryWord={query}
                                    link={"/news-and-events/events"}
                                />
                            )}
                            {data?.reports.length > 0 && (
                                <BlogSearch
                                    posts={data?.reports}
                                    title={"Reports"}
                                    queryWord={query}
                                    link={"/publications/reports"}
                                />
                            )}
                            {data?.video_gallery.length > 0 && (
                                <BlogSearch
                                    posts={data?.video_gallery}
                                    title={"Videos"}
                                    queryWord={query}
                                />
                            )}
                            {data?.image_gallery.length > 0 && (
                                <BlogSearch
                                    posts={data?.image_gallery}
                                    title={"Photos"}
                                    queryWord={query}
                                />
                            )}
                        </>
                    ) : (
                        <div
                            style={{
                                height: "30vh",
                                display: "grid",
                                placeItems: "center",
                                fontWeight: "600",
                            }}
                        >
                            <p>No result found for "{query}"</p>
                        </div>
                    )}
                </>
            )}
        </main>
    );
};

export default SearchResults;
