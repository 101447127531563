import React from "react";
// import Link from "next/link";

import moment from "moment";
import { NavLink } from "react-router-dom";

export default function EachBlog({
    link,
    title,
    category,
    created_at,
    img,
    alt,
    short_description,
    categoryLink,
}) {
    return (
        <article
            className='col-lg-6 col-md-6'
            bis_skin_checked='1'
            data-aos='fade-up'
            // style={{ boxShadow: "1px 2px 2px gray" }}
        >
            <div className='single-blog-box' bis_skin_checked='1'>
                <NavLink
                    className='w-100 relative '
                    style={{
                        minHeight: "200px",
                        position: "relative",
                        width: "500px",
                        display: "block",
                    }}
                    to={link}
                >
                    <img
                        fill
                        src={img}
                        alt={alt || "blog post image"}
                        style={{
                            objectFit: "cover",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                        }}
                    />
                </NavLink>

                <div className='blog-content' bis_skin_checked='1'>
                    <ul>
                        <li
                            style={{
                                textTransform: "capitalize",
                                background: "#e7f4eb",
                                color: "white",
                                padding: "2px 6px",
                                borderRadius: "8px",
                            }}
                        >
                            <NavLink to={categoryLink || "#"}>
                                {category}
                            </NavLink>
                        </li>
                        <li>
                            <i className='ri-calendar-line'></i>
                            {moment(created_at).format("DD MMMM, YYYY")}
                        </li>
                    </ul>
                    <h3 className='fw-500'>
                        <NavLink to={link}>{title}</NavLink>
                    </h3>
                    <p>{short_description}</p>
                    <NavLink to={link} className='read-more'>
                        Read Post
                        <i className='ri-arrow-right-s-line'></i>
                    </NavLink>
                </div>
            </div>
        </article>
    );
}
