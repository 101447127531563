import React from "react";
import { NavLink } from "react-router-dom";

export default function BlogRight({ categories, link }) {
    console.log(categories);
    // Prismic does not support alphabetic order sorting yet
    // This will sort the categories manually in alphabetic order
    const sortInAphabeticOrder = categories?.sort((a, b) => {
        console.log("This is a", a?.data);
        const titleA = a?.data?.title[0]?.text?.toLowerCase();
        const titleB = b?.data?.title[0]?.text?.toLowerCase();

        if (titleA < titleB) {
            return -1;
        } else if (titleA > titleB) {
            return 1;
        }

        return 0;
    });

    return (
        <div bis_skin_checked='1'>
            <div className='widget-sidebar pl-15' bis_skin_checked='1'>
                <div className='sidebar-widget categories' bis_skin_checked='1'>
                    <h3>Categories</h3>

                    <ul>
                        {sortInAphabeticOrder?.map((category) => (
                            <li key={category.uid}>
                                <NavLink to={`${link}${category.uid}`}>
                                    {category?.data?.title[0]?.text}
                                    <i className='ri-arrow-right-s-line'></i>
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}
