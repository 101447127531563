import React from "react";
import { usePrismicClient } from "@prismicio/react";
import { useSearchParams } from "react-router-dom";
import * as prismic from "@prismicio/client";
import { useQuery } from "@tanstack/react-query";
import SearchResults from "../../components/Search/SearchResults";
const SearchPage = () => {
    const [searchParams] = useSearchParams();
    const client = usePrismicClient();
    const [data, setData] = React.useState(null);
    const results = client.get({
        fetchLinks: ["category.title", "media_room_category.title"],
        filters: [
            prismic.filter.any("document.type", [
                "blog",
                "video_galle",
                "photo_gallery",
                "news",
                "report",
                "event",
            ]),
            prismic.filter.fulltext("document", searchParams.get("q")),
        ],
        orderings: {
            field: "document.first_publication_date",
            direction: "desc",
        },
        pageSize: 10,
        page: searchParams.get("page") || 1,
    });
    const [isFetching, setIsFetching] = React.useState(false);

    // const { data, isLoading, isFetching } = useQuery({
    //     queryKey: ["search"],
    //     queryFn: () => results,
    //     enabled: !!searchParams.get("q"),
    // });

    const fetchData = async () => {
        setIsFetching(true);
        const response = await results;
        setData(response);
        setIsFetching(false);
    };

    React.useEffect(() => {
        fetchData();
    }, [searchParams.get("q")]);

    return (
        <>
            <SearchResults
                posts={data?.results}
                totalPages={data?.total_pages}
                total_result={data?.total_results_size}
                query={searchParams.get("q")}
                isLoading={isFetching}
            />
        </>
    );
};

export default SearchPage;
